<template>
  <div class="version-update">
    <div class="description">{{ $t('description') }}</div>
    <PActions class="update-actions">
      <DSButton variant="secondary" @click="later">
        {{ $t('later') }}
      </DSButton>
      <DSButton @click="refresh">
        {{ $t('refresh') }}
      </DSButton>
    </PActions>
  </div>
</template>

<i18n lang="yaml">
en:
  description: Refresh to get the latest version. Not refreshing may cause issues.
  later: Later
  refresh: Refresh
</i18n>

<script lang="ts" setup>
import { DSButton } from '@prolific-oss/design-system'
import { trackRefreshClicked, trackRefreshCancelled } from '@/analytics/common'
import PActions from '@/components/form/Actions.vue'

const emits = defineEmits(['later', 'refresh'])

function later() {
  trackRefreshCancelled()
  emits('later')
}

function refresh() {
  trackRefreshClicked()
  emits('refresh')
}
</script>

<style lang="scss" scoped>
.update-actions {
  margin-top: $space-5;
}

.description {
  margin-left: 32px;
}
</style>
