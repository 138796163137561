import { trackStructEvent } from '@/integrations/snowplow'

const appVersion = import.meta.env['VUE_APP_GIT_HEAD_SHA'] ?? 'unknown'

export const trackRefreshCancelled = () => {
  trackStructEvent({
    category: 'notification',
    action: 'refresh-cancelled',
    label: appVersion,
  })
}

export const trackRefreshClicked = () => {
  trackStructEvent({
    category: 'notification',
    action: 'refresh-clicked',
    label: appVersion,
  })
}
